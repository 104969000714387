import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import Hero from './components/Hero';
import Benefits from './components/Benefits';
import How from './components/How';
import Faq from './components/Faq';
import Overview from './components/Overview';
import Contact from './components/Contact';
import FadeInSection from './components/FadeInSection';
import About from './components/About';
import Portfolio from './components/Portfolio';
import Products from './components/Products';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
     <Header />
     <Hero/>
     {/* <FadeInSection> */}

     <Overview/>
     {/* </FadeInSection> */}
      <Portfolio/>
     <How/>
      {/* <Products/>
     <Benefits/> */}
     {/* <About/> */}
     {/* <Faq/> */}
     <Contact/>
     <Footer/>
    </div>
  );
}

export default App;
