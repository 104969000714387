import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({

    inputField:{
        width: '100%' , background:'rgb(188 185 185)', border:'none', borderRadius:8, height:30, padding:'0px 10px 0px 10px',fontFamily:'Nunito Sans, sans-serif',
        '&::placeholder': {
            // textOverflow: 'ellipsis !important',
            color: 'white'
          }
    }


}))


const FormModal = props => {
    const classes = useStyles();
 

 
    return (
        <>
          <style>

@import url('https://fonts.googleapis.com/css2?family=Arimo&family=Kalam&family=Nunito+Sans:opsz@6..12&display=swap');


</style>

            <div style={{
                position: 'fixed',
                width: '100%',
                height: ' 100vh',
                top: 0,
                left: 0,
                zIndex: '9999',
                background: '#00000096',
                color: '#fff',
                display: 'flex',
                alignItems: 'center',
            }}>
                <div style={{
                    width: '500px',
                    margin: '0 auto',
                    background: 'rgb(255 255 255 / 85%)',
                    color: '#484848',
                    textAlign: 'center',
                    padding: '16px 24px 16px 24px',
                    borderRadius: 10,
                    //   border:'6px solid #00b188',
                }}>
                    <div onClick={props.closeModal} style={{width:'100%', textAlign:'end' , cursor:'pointer'}}>x</div>
                    <form onSubmit={props.onSubmit} style={{ width: '95%',  }}>
                        <div>
                            <label style={{ display: 'block', textAlign: 'left', padding: '15px 0px 5px 0px', fontWeight:500,fontFamily:'Nunito Sans, sans-serif' }}>Name:</label>
                            <input
                            className={classes.inputField}
                                // style={{ width: '100%' , background:'rgb(188 185 185)', border:'none', borderRadius:8, height:30, padding:'0px 10px 0px 10px',fontFamily:'Nunito Sans, sans-serif' }}
                                type='text'
                                name='name'
                                id='name'
                                placeholder='Name...'
                                required
                                // value={props.name}
                                // onChange={e => setFormData(prev => ({
                                //     ...prev,
                                //     name: e.target.value,
                                // }))}
                                onChange={props.handleChange}
                            />
                        </div>

                        <div>
                            <label style={{ display: 'block', textAlign: 'left',padding: '15px 0px 5px 0px', fontWeight:500,fontFamily:'Nunito Sans, sans-serif'  }}>Company Name:</label>

                            <input
                            className={classes.inputField}

                                type='text'
                                name='company'
                                id='company'
                                placeholder='Company Name...'
                                required
                                // value={props.company}
                                onChange={props.handleChange}

                            />
                        </div>

                        <div>
                            <label style={{ display: 'block', textAlign: 'left', padding: '15px 0px 5px 0px', fontWeight:500,fontFamily:'Nunito Sans, sans-serif' }}>Email:</label>

                            <input
                            className={classes.inputField}

                                type='email'
                                name='email'
                                id='email'
                                placeholder='Email Address...'
                                required
                                // value={props.email}
                                onChange={props.handleChange}

                            />
                        </div>

                        {/* <div>
                            <label style={{ display: 'block', textAlign: 'left', padding: '15px 0px 5px 0px', fontWeight:500 ,fontFamily:'Nunito Sans, sans-serif' }}>Phone:</label>

                            <input
                            className={classes.inputField}

                                type='tel'
                                name='phone'
                                id='phone'
                                // value={props.phone}
                                placeholder='Phone Number...'
                                onChange={props.handleChange}

                            />
                        </div> */}

                        <div>
                            <label style={{ display: 'block', textAlign: 'left', padding: '15px 0px 5px 0px', fontWeight:500 ,fontFamily:'Nunito Sans, sans-serif' }}>Remarks:</label>

                            <input
                            className={classes.inputField}

                                type='text'
                                name='remarks'
                                placeholder='Any Remarks...'
                                id='remarks'
                                // value={props.remarks}
                                onChange={props.handleChange}

                            />
                        </div>

                        <div style={{marginTop:20, marginBottom:20, }}>
                            <button type='submit' style={{border:'none', background:'#4d4b4b', borderRadius:4, height:40, width:150 , color:'white', fontWeight:500 , cursor:'pointer' ,fontFamily:'Nunito Sans, sans-serif'}}>Submit Query</button>
                        </div>

                    </form>

                </div>


            </div>
        </>
    )
}

export default FormModal