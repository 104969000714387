import React,{useState} from 'react'
import { makeStyles } from '@material-ui/core'
import { AppBar, Toolbar, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import acayaLogo from '../images/Acaya-loggo-385x77 (1).png';

const useStyles = makeStyles(theme => ({

    mainHeader: {
        height: '2.8rem',
        zIndex: 100,
        width: '100%',
        background: '#eaeaea',
        position: 'sticky',
        opacity:0.7,
        // borderBottom:'1px solid grey',
        top: 0,
        left: 0,
        [theme.breakpoints.down('xs')]: {
            height:'3.2rem',
             },
       
    },

    rowHeader: {
        height: '2.8rem',
        minHeight: 0,
        display: 'flex',
        justifyContent:'flex-end',
        paddingRight:20,
        [theme.breakpoints.down('xs')]: {
            height:'3.2rem',
             },
       
        
        '& nav': {
            display: 'block !important',
            
        },
        '& ul': {
            display: 'block',
            textAlign: 'center',
            padding: 0,
            margin: 0,
            [theme.breakpoints.down('xs')]: {
                display:'none',
          
              },
              [theme.breakpoints.down('sm')]: {
                display:'none',
          
              },
        },
        '& li': {
            listStyle: 'none',
            display: 'inline-block',
            position: 'relative',
            padding: 0,
            margin: 0,
        },
        '& a': {
            display: 'block',
            height: '2.8rem',
            lineHeight: '2.8rem',
            padding: '0px 1.6rem',
            cursor: 'pointer',
            fontWeight: 600,
            fontFamily:'Nunito Sans, sans-serif',
            color: 'black',
            textDecoration: 'none',
            [theme.breakpoints.down('xs')]: {
                fontSize: 10,
                padding: '0px 0.5rem',

            },

            '&:hover': {
              fontWeight:700,
            }
        }

    },
    hamburger:{
    position:'absolute',
    right:0,
    display:'flex',
    alignItems:'center',
    height:'100%',
   
    [theme.breakpoints.down('md')]: {
        display:'none',
 
     },
     [theme.breakpoints.down('lg')]: {
        display:'none',
 
     },
    [theme.breakpoints.down('xs')]: {
      display:'flex',

    },
    [theme.breakpoints.down('sm')]: {
      display:'flex',

   },
    },
    hamUi:{
        background:'transparent !important',
        color:'white !important',
        boxShadow:'none !important'
    },
    list:{
    '& a':{
        display: 'block',
        height: '2.8rem',
        lineHeight: '2.8rem',
        padding: '0px 1.6rem',
        cursor: 'pointer',
        fontWeight: 600,
        color: 'black',
        textDecoration: 'none',
        fontFamily:'Nunito Sans, sans-serif',
    }

    },
    logoAcaya:{
        position:'absolute',
        left:30,
        display:'flex',
        alignItems:'center',
        height:'100%',

        [theme.breakpoints.down('xs')]: {
          left:10,
  
        },
        // marginLeft:'10%',
        '& img' :{
          width:'30%',
          [theme.breakpoints.down('xs')]: {
            width:'20% !important',

          },
          // height:'80%',
        },
       

        
    },

    tagline:{
      fontSize:32,
      padding:'10px 0px 10px 0px',
      textTransform:'uppercase',
      fontWeight:600,
      letterSpacing:5,
      [theme.breakpoints.down('xs')]: {
        fontSize:20,

      },
    },

    soonBanner:{
      background:'black',
      fontSize:20,
      padding:10,
      fontFamily:'Nunito Sans, sans-serif',
      fontWeight:700,
      color:'#ffff'
    
    }

}))

const Header = () => {
    const classes = useStyles();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const handleDrawerToggle = () => {
      setIsDrawerOpen(!isDrawerOpen);
    }
  
    return (
      
      <>
      <style>
@import url('https://fonts.googleapis.com/css2?family=Arimo&family=Kalam&family=Nunito+Sans:opsz@6..12&display=swap');
</style>
<div className={classes.soonBanner}>Acaya's new look is coming soon!</div>

        <div style={{ position: 'sticky', top: 0, zIndex: 999 }}>
            <header className={classes.mainHeader}>
                <div className={classes.rowHeader}>
                <div className={classes.logoAcaya}>
<img src={acayaLogo} alt='acaya'/>
                </div>

                    <nav>
                        <div>
                            <ul>
                                <li><a href='#overview'> About Us </a></li>
                                <li><a href='#portfolio'> Hot Box </a></li>

                                <li><a href='#coldBox'> Cold Box </a></li>
                                <li><a href='#contact'> Reach Us </a></li>
                                {/* <li><a href='#about'> About </a></li> */}
{/* 
                                <li><a href='#faq'> FAQs </a></li>
                                <li><a href='#contact'> Contact </a></li> */}
                            </ul>
                        </div>
                    </nav>
                    <div className={classes.hamburger}>
                    <AppBar className={classes.hamUi} position="static">
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleDrawerToggle}
          style={{color:'black'}}
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          anchor="left"
          open={isDrawerOpen}
          onClose={handleDrawerToggle}
        >
          <List className={classes.list}>
            <ListItem button onClick={handleDrawerToggle}>
              <a href='#overview'> About Us </a> 
            </ListItem>
            <ListItem button onClick={handleDrawerToggle}>
              <a href='#portfolio'> Hot Box </a> 
            </ListItem>
            <ListItem button onClick={handleDrawerToggle}>
            <a href='#coldBox'> Cold Box </a> 
            </ListItem>
            <ListItem button onClick={handleDrawerToggle}>
              <a href='#benefits'> Reach Us </a> 
            </ListItem>
            {/* <ListItem button onClick={handleDrawerToggle}>
              <a href='#about'> About </a> 
            </ListItem> */}
          
            {/* Add more menu items as needed */}
          </List>
        </Drawer>
      </Toolbar>
    </AppBar>
                </div>
                </div>
               
            </header>
            
        </div>
        {/* <div className={classes.tagline}> 
          The future of food packaging
      </div> */}
      </>
    )
}

export default Header