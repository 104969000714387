import React from 'react'
import { Grid } from '@mui/material'
import textBox2 from '../images/BOX6-Recovered.png'
import { makeStyles } from '@material-ui/core'
import howImage from '../images1/Artboard Acaya 11.jpg'

const useStyles = makeStyles(theme => ({

    sectionHow: {
        // paddingTop: '1rem',
        borderBottom: '1px solid #e0e0e0',
        width:'95%',
        margin:'0 auto', 

        [theme.breakpoints.down('sm')]: {
            // paddingTop: '1rem',
            width:'100%',

        },

    },
    howHead: {
        fontWeight: 700,
        fontSize: 48,
        marginTop: 40,
        // textShadow:'4px 4px #e0e0e0',
        letterSpacing: '5px',
        textTransform: 'capitalize',
        [theme.breakpoints.down('xs')]: {
            fontSize: 24,
            marginTop:0,
        },
        // "&:after":{
        //     content:'',
        //     display:'block',
        //     height:2,
        //     width:'100%',
        //     backgroundColor:'red',
        //     position:'absolute',
        //     left:0,
        //     bottom:0,
        // }
    },

    howContent: {
        margin: '0px 0 40px 0'
    },
    leftSide: {
        // border:' 2px solid black',
        width: '100%',
        margin: 'auto',
        '& img': {
            width: '-webkit-fill-available',
        }
    },

    rightSide: {
        width: '100%',
        margin: '50px auto 50px auto ',
        textAlign: 'left',
        fontSize: 20,
        lineHeight: '2rem',
        [theme.breakpoints.down('sm')]: {
            margin: 0,
            fontSize: 16,
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 12,
            margin: '10px auto 10px auto',
            width: '80%',
            lineHeight: '1.4rem',
        },
    },

    howImage: {
        display: 'block',
        width: '100%',
        height: '100%',
        margin:'0 auto',


        '& img': {
            height: '60%',
            width: '-webkit-fill-available',
            // height:'-webkit-fill-available',
        }
    }




}))

const How = () => {
    const classes = useStyles()
    return (
        <div>
            <section id='how' className={classes.sectionHow}>
                <div className={classes.howContent}>
                    <div className={classes.howImage}>
                        <img src={howImage} alt='how' />
                    </div>
                
                </div>
            </section>
        </div>
    )
}

export default How