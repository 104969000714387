import React from 'react'
import { makeStyles } from '@material-ui/core'
const useStyles = makeStyles(theme => ({
sectionO:{
 paddingTop:'3.2rem',
 borderBottom: '10px solid #e0e0e0',
 paddingBottom  :'3.2rem',
 [theme.breakpoints.down('xs')]: {
    paddingTop:'1.2rem',
},
},

    overviewHead: {
        fontWeight: 700,
        fontSize: 48,
        marginTop: 40,
        // textShadow:'4px 4px #e0e0e0',
        [theme.breakpoints.down('xs')]: {
            fontSize: 30,
        },

    },

    overviewOuter: {
        display: 'flex', justifyContent: 'center',  margin: '20px auto 20px auto',
        width:'60%', lineHeight:'2.2rem',
        [theme.breakpoints.down('sm')]: {
            width:'90%',
            lineHeight:'1.4rem'
         },
    },

    overviewContent: {
        fontSize: 20,
        // textShadow:'2px 2px #e0e0e0',
        textAlign:'justify',
        fontWeight: 500,
        [theme.breakpoints.down('xs')]: {
            fontSize: 12,
            textAlign:'start',

        },


    },


}))

const About = () => {
    const classes = useStyles()
    return (

        <div>
            <section id="about" className={classes.sectionO}>
                <div className={classes.overviewHead} >About Acaya</div>
                <div style={{paddingTop:10 ,fontSize: 20,fontWeight:600, }}>Introducing the revolutionary Food Packaging Solution Acaya.</div>
                <div className={classes.overviewOuter} >
                    <div className={classes.overviewContent}>
                    Acaya is a game-changer when it comes to keeping your food Hot, Fresh and Delicious for an incredible 72 hours. Designed with cutting-edge technology, this innovative food packaging is here to transform the food delivery industry. <br/>
                    A smart food packaging solution that uses sensors, data analytics, and other advanced features to help improve the quality, safety, and sustainability of food products. The technology is designed to monitor the condition and freshness of the food and alerts us if any issues could impact the product's quality or safety. It will help restaurants and food providers maintain a competitive edge, enhance customer satisfaction, and achieve greater efficiency in their delivery processes.


                    </div>
                </div>
            </section>
         </div>
    )
}

export default About