import React, { useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import axios from 'axios'
import background from '../images1/contact4.jpg'


const useStyles = makeStyles(theme => ({
    sectionContact: {
        paddingTop: '1.2rem',
        // borderBottom: '1px solid #e0e0e0',
        paddingBottom: '1.2rem',
        // background: '#eaeaea',
        width:'95%',
        margin:'0 auto', 

        // background:'#7e2022',
        [theme.breakpoints.down('sm')]: {
            //   paddingBottom:0,
            paddingTop:0,
            width:'100%',

        },

    },

    background:{
     backgroundImage:`url(${background})`,
     backgroundPosition: 'top',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  height: '100vh',

  [theme.breakpoints.down('sm')]: {
    // background:'#eaeaea',
    height:'100%',
  },

  [theme.breakpoints.down('xs')]: {
    background:'#eaeaea',
    height:'100%',
  }
    },

    contactHead: {
        fontWeight: 700,
        fontSize: 32,
        display:'none',
        // marginTop: 10,
        color: 'black',
        textAlign: 'left',
        paddingLeft: 20,
        fontFamily:'Nunito Sans, sans-serif',

        // textShadow:'1px 1px #e0e0e0',
        // letterSpacing:'5px',

        [theme.breakpoints.down('xs')]: {
            fontSize: 24,
            display:'block',
            padding:10,
        },
    },
    contactContent: {
        margin: '40px 0 40px 0',
        fontFamily:'Nunito Sans, sans-serif',
        '& .emailUs': {
            fontSize: 20,
            fontWeight: 600,
            color: '#ffff',
            [theme.breakpoints.down('xs')]: {
                fontSize: 14,
            },
            '& a': {
                color: '#ffff',
                textDecoration: 'none',
                [theme.breakpoints.down('xs')]: {
                    fontSize: 14,
                },
            }
        }
    },

    formMain: {
        maxWidth: '600px',
        margin: '20% auto ',
        background: 'rgb(255 255 255 / 48%)',
        boxShadow:'0 3px 10px rgb(0 0 0 / 0.2)',
        color: '#484848',
        textAlign: 'center',
        padding: '48px 28px 48px 28px',
        borderRadius: 10,
        fontFamily:'Nunito Sans, sans-serif',
        [theme.breakpoints.down('sm')]: {
     maxWidth: '500px',
     margin:20,

        },

        '& .formStyle': {
            width: '95%',

            '& label': {
                display: 'block', textAlign: 'left', padding: '15px 0px 5px 0px', fontWeight: 500 , fontSize:14,
            },
            '& input': {
                width: '100%', background:'rgb(188 185 185)', border: 'none', borderRadius: 8, height: 30, padding: '0px 10px 0px 10px',
                '&::placeholder': {
                    // textOverflow: 'ellipsis !important',
                    color: 'white',
                    fontFamily:'Nunito Sans, sans-serif',

                  }
            },
            '& button':{
              marginTop:20 , border:'none', background:'#4d4b4b', borderRadius:4, height:40, width:150 , color:'white', fontWeight:500 , cursor:'pointer' ,                    fontFamily:'Nunito Sans, sans-serif',

            }

        }
    },

    rightSideContact:{
        display:'block',
        fontFamily:'Nunito Sans, sans-serif',
        marginTop:50,
        [theme.breakpoints.down('sm')]: {
            padding:20,
        },

        '& .right':{
            paddingBottom:25,
            fontSize:22,
            borderBottom:'1px solid black',
            width:'80%',
            textAlign:'left',
            marginBottom:30,

        },
        '& .subRight':{
            paddingBottom:25,
            width:'80%',
            textAlign:'left',
            marginBottom:30,

        },

        '& .socialIcons':{
            display:'flex',
            justifyContent:'center',
            gap:14,

            '& .icons':{
                maxWidth:30,
                '& img':{
                    width:'-webkit-fill-available',
                }
            }
        },

        '& .rights':{
            display:'flex',
            justifyContent:'center',
            fontSize:14,
            marginTop:10,
        }

    },

    

  

}))

const intitialValues = {
    name: '',
    company: '',
    email: '',
    phone: '',
    remarks: '',

}

const Contact = () => {
    const classes = useStyles()
    const [formData, setFormData] = useState(intitialValues)
   

    const onSubmit = (e) => {
        e.preventDefault()
        console.log(formData)
        axios.post('https://apistaging.justmyroots.com/api/contact-us-acaya',{
            name:formData.name,
            email:formData.email,
            // mobileNumber:formData.phone,
            remarks:formData.remarks,
            company_name:formData.company,
          })
          .then((res)=>{
             if(res.data.success === true){
            //   setShowForm(false)
              setFormData(intitialValues)
              alert('Thank You, We have received your query and will respond soon!')
             }
          })
    }

    return (
        <div>
              <style>
@import url('https://fonts.googleapis.com/css2?family=Arimo&family=Kalam&family=Nunito+Sans:opsz@6..12&display=swap');
</style>
            <section id='contact' className={classes.sectionContact}>
                <div className={classes.background}>
                <div className={classes.contactHead}>Reach out to us</div>
                <Grid container>
                    <Grid item xs={12} sm={7}>
                        <div className={classes.formMain}>
                            <form onSubmit={onSubmit} className='formStyle'>
                                <div>
                                    <label>
                                        Name:
                                    </label>
                                    <input
                                        // style={{ width: '100%' , background:'#ece9e9', border:'none', borderRadius:8, height:30, padding:'0px 10px 0px 10px' }}
                                        type='text'
                                        name='name'
                                        id='name'
                                        placeholder='Name...'
                                        required
                                        value={formData.name}
                                        onChange={e => setFormData(prev => ({
                                            ...prev,
                                            name: e.target.value,
                                        }))}
                                    />
                                </div>

                                <div>
                                    <label >
                                        Company Name:
                                    </label>

                                    <input
                                        // style={{ width: '100%' , background:'#ece9e9', border:'none', borderRadius:8, height:30, padding:'0px 10px 0px 10px' }}

                                        type='text'
                                        name='company'
                                        id='company'
                                        placeholder='Company Name...'
                                        required
                                        value={formData.company}
                                        onChange={e => setFormData(prev => ({
                                            ...prev,
                                            company: e.target.value,
                                        }))}

                                    />
                                </div>

                                <div>
                                    <label >Email:</label>

                                    <input
                                        // style={{ width: '100%' , background:'#ece9e9', border:'none', borderRadius:8, height:30, padding:'0px 10px 0px 10px' }}

                                        type='email'
                                        name='email'
                                        id='email'
                                        placeholder='Email Address...'
                                        required
                                        value={formData.email}
                                        onChange={e => setFormData(prev => ({
                                            ...prev,
                                            email: e.target.value,
                                        }))}
                                    />
                                </div>
                                {/* <div>
                                    <label >Phone:</label>

                                    <input
                                        // style={{ width: '100%' , background:'#ece9e9',  border:'none', borderRadius:8, height:30, padding:'0px 10px 0px 10px' }}

                                        type='tel'
                                        name='phone'
                                        id='phone'
                                        // value={props.phone}
                                        placeholder='Phone Number...'
                                        value={formData.phone}
                                        onChange={e => setFormData(prev => ({
                                            ...prev,
                                            phone: e.target.value,
                                        }))}

                                    />
                                </div> */}

                                <div>
                                    <label >Remarks:</label>

                                    <input
                                        // style={{ width: '100%' , background:'#ece9e9', border:'none', borderRadius:8, height:30, padding:'0px 10px 0px 10px' }}

                                        type='text'
                                        name='remarks'
                                        placeholder='Any Remarks...'
                                        id='remarks'
                                        value={formData.remarks}
                                        onChange={e => setFormData(prev => ({
                                            ...prev,
                                            remarks: e.target.value,
                                        }))}

                                    />
                                </div>
                                <div >
                                    <button type='submit' >Submit Query</button>
                                </div>

                            </form>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={5}>
                          {/* <div className={classes.rightSideContact}>
                            <div className='right'>
                                Acaya Headquarters
                            </div>

                            <div className='subRight'>
                                 Acaya <br/>
                                 1207, 12th Floor <br/>
Unitech Signature Towers, Sector-30,<br/>
 Gurugram - 122001

                            </div>

                            <div className='right'>
                            For Sales & Support
                            </div>

                            <div className='subRight'>
                            0124 - 4032### <br/>
                            Reach out to us at care@acaya.com

</div>


                          </div> */}
                    </Grid>

                </Grid>
                </div>
            </section>
        </div>
    )
}

export default Contact