import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import aboutUs from '../images1/Artboard Acaya 2.jpg'
import { Grid } from '@mui/material'
import imgExample from '../images/BOX6-Recovered.png'

const useStyles = makeStyles(theme => ({
sectionO:{
 paddingTop:'1rem',
//  paddingBottom  :'2.2rem',
width:'95%',
margin:'0 auto', 

// maxHeight:200,

'& img':{
     width:'-webkit-fill-available',
     height:'100%',
},
 [theme.breakpoints.down('sm')]: {
    // paddingTop:'1.2rem',
    width:'100%',
},
},

    overviewHead: {
        fontWeight: 700,
        fontSize: 32,
        marginTop: 40,
        letterSpacing:'5px',
        [theme.breakpoints.down('xs')]: {
            fontSize: 30,
        },
        '& img':{
            width:'11%',
            paddingTop:10,

            [theme.breakpoints.down('xs')]: {
                width:'25%',
            },
            [theme.breakpoints.down('sm')]: {
                width:'20%',
            },
        }

    },

    overviewOuter: {
        display: 'flex', justifyContent: 'center',  margin: '20px auto 20px auto',
        width:'90%', lineHeight:'2.2rem',
        [theme.breakpoints.down('sm')]: {
            width:'90%',
            lineHeight:'1.4rem'
         },
    },

    overviewContent: {
        fontSize: 18,
        // textShadow:'2px 2px #e0e0e0',
        textAlign:'justify',
        fontWeight: 500,
        [theme.breakpoints.down('xs')]: {
            fontSize: 12,
            textAlign:'start',

        },


    },
    readMore:{
        color: '#AC1715',
        // textDecoration: 'none',
        fontWeight: 600,
        fontSize: 16,
        background:'transparent',
        border:'none',
        cursor:'pointer',
        [theme.breakpoints.down('xs')]: {
            fontSize: 12,
            // textAlign:'start',

        },

    },

    rightSideAbout:{
        '& img':{
            width:'-webkit-fill-available',

        }
    }


}))

const Overview = () => {
    const classes = useStyles()
    const [readMore , setReadMore] = useState(false)

    const toggleReadMore = ()=>{
        setReadMore(!readMore)
    }
    return (

        <div>
            <section id="overview" className={classes.sectionO}>

                <div>
                    <img src={aboutUs} alt='' />
                </div>
                {/* <Grid container spacing={4} mt={6}>
                 
                <Grid item xs={12} sm={6}>
                  <div className={classes.rightSideAbout}>
                    <img src={imgExample} alt='example' />
                  </div>
                </Grid>

                <Grid item xs={12} sm={6}>

<div className={classes.overviewOuter} >
    <div className={classes.overviewContent}>

    Introducing the revolutionary Food Packaging Solution Acaya. A smart food packaging solution that uses a special IP-based technology, which ensures that the food is fresh and Hot/cold depending on the requirements.Acaya is a game-changer when it comes to keeping your food Hot, Fresh and Delicious for an incredible 72 hours. Designed with cutting-edge technology, this innovative food packaging is here to transform the food delivery industry. It will help restaurants and food providers maintain a competitive edge, enhance customer satisfaction, and achieve greater efficiency in their delivery processes.

     <button style={{display: readMore ? 'none':''}} className={classes.readMore} onClick={toggleReadMore} >
    Read More </button> 
{readMore && (
<>
<br/>India faces a significant issue of agricultural wastage, with up to 16% of its produce going to waste, particularly fruits and vegetables. Meanwhile, food delivery companies worldwide often deliver meals in either warm or cold conditions, which is undesirable. Popular dishes like Dosa, French Fries, and Fritters tend to lose their signature crispiness and become soggy when ordered through food delivery platforms. Additionally, the supply chain costs associated with transportation are notably high. These examples highlight just a few instances of the ongoing challenges and complexities in the food industry. The list of such issues can be seemingly endless, requiring continuous efforts to address and improve the food delivery experiences and also reduce wastage.
</>
)}  
    </div>
</div>
</Grid>

                </Grid> */}

            </section>
         </div>
    )
}

export default Overview