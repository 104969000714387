import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import backgroundImg from '../images1/hero2.jpg'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import FormModal from './FormModal';
import axios from 'axios';

const useStyles = makeStyles(theme => ({
 heroSection:{
    width:'95%',
    // height:'100vh',
    maxWidth:1800,
    maxHeight:1080,
    // maxWidth:1600,
    overflow:'hidden',
    position:'relative', 
    margin:'0 auto', 
    
    [theme.breakpoints.down('sm')]: {
      width:'100%',

  },
 },
 bgImage:{
  // width:'100vw',
  // height: '100vh',
  // backgroundImage: `url(${backgroundImg})`,
  // backgroundPosition:'center',
  // backgroundSize: 'cover',
  // backgroundRepeat: 'no-repeat',
  position:'relative',
  // display: 'flex',
  // justifyContent: 'center',
  // alignContent:'center',
  // alignItems:'center',
  // textAlign: 'center',
  width: '100%',
  height: '100%',
  margin:'0 auto',
  '& img': {
    height: '60%',
    width: '-webkit-fill-available',
    // height:'-webkit-fill-available',
},
  
    // position:'absolute',
    // top:0,
    // right:0,
    // left:0,
    // bottom:0,
    // width:'100%',
    // height:'100%',

    '& .heroContent':{

      '& button':{
        position:'absolute',
        top:'28%',
        left:'43%',
        transform:'translate (-50%,-50%)',
        boxShadow:'0 3px 10px rgb(0 0 0 / 0.2)',
        border:'none',
        width:200,
        height:60,
        borderRadius:8,
        background:'#ffffff8a',
        color:'black',
        fontSize:24,
        cursor:'pointer',
        fontWeight:700,
        fontFamily:'Nunito Sans, sans-serif',
        marginBottom:'200px',
        [theme.breakpoints.down('sm')]: {
          width:80,
          height:20,
          fontSize:8,
           top:'30%',
           left:'40%',
      },
      }
    },

    [theme.breakpoints.down('sm')]: {
      // height:'40vh',
  },
   
 }, 
 carousel :{
  maxWidth: '800px',
  margin: '0 auto',
},

}))

const intitialValues = {
  name: '',
  company: '',
  email: '',
  phone: '',
  remarks: '',

}

const Hero = () => {
    const classes = useStyles();
    const[showForm , setShowForm] = useState(false)
    const [formData, setFormData] = useState(intitialValues)

    const closeModal = ()=>{
      setShowForm(false)
      setFormData(intitialValues)
    }

    const handleChange =(e)=>{
      setFormData({
        ...formData,[e.target.name]:e.target.value
      });
    }

    const openModal = () =>{
      setShowForm(true)
    }

    const onSubmit=(e)=>{
      e.preventDefault()
      axios.post('https://apistaging.justmyroots.com/api/contact-us-acaya',{
        name:formData.name,
        email:formData.email,
        // mobileNumber:formData.phone,
        remarks:formData.remarks,
        company_name:formData.company,
      })
      .then((res)=>{
         if(res.data.success === true){
          setShowForm(false)
          setFormData(intitialValues)
          alert('Thank You, We have received your query and will respond soon!')
         }
      })

    }
  return (
    <div>
         <style>
@import url('https://fonts.googleapis.com/css2?family=Arimo&family=Kalam&family=Nunito+Sans:opsz@6..12&display=swap');
</style>
      {showForm && <FormModal 
      closeModal ={closeModal}
      handleChange={handleChange}
      onSubmit={onSubmit}
      />}
        <section id='hero' className={classes.heroSection}>
          {/* <div className={classes.soonBanner}>New website is coming soon!</div> */}
          {/* <Carousel showArrows={true} showStatus={false} showThumbs={false} autoPlay={true} swipeable={true} infiniteLoop={true}> */}
         <div className={classes.bgImage}>
<img src={backgroundImg} alt="test" />
  <div className='heroContent'>
  <button onClick={openModal}>Get a trial</button>
  </div>
         </div>
      
         {/* </Carousel> */}
        </section>
    </div>
  )
}

export default Hero