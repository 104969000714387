import React from 'react'
import { makeStyles } from '@material-ui/core'
import food1 from '../images/food hot product banner eith text.jpg'
import food2 from '../images/acaya vegetagles and fr;luites image with text.jpg'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const useStyles = makeStyles(theme =>({
  sectionProducts:{
    paddingTop:'1.2rem',
    borderBottom: '1px solid #e0e0e0',

    // paddingBottom  :'3.2rem',
    [theme.breakpoints.down('xs')]: {
        paddingTop:'1rem',

         },
  },
  productsHead:{
    fontWeight: 700,
    fontSize: 48,
    marginTop: 40,
    // textShadow:'4px 4px #e0e0e0',
    letterSpacing:'5px',

    [theme.breakpoints.down('xs')]: {
        fontSize: 24,
    },
  },

  featureContent:{
    margin:'40px 0 40px 0'

  },
  
  featureImages:{
    display:'block',
    width:'100%',
    height:'100%',
    margin:'0 auto',
    
    '& img':{
        height:'100%',
        width:'-webkit-fill-available',
        // height:'-webkit-fill-available',
    }   
  },


}))

const Products = () => {
    const classes = useStyles()
  return (
    <div>
    <section id='products' className={classes.sectionProducts}>
    <div className={classes.productsHead}>
      Applications
    </div>
    <div className={classes.featureContent}>
    <Carousel showArrows={true} showStatus={false} showThumbs={false} autoPlay={true} swipeable={true} infiniteLoop={true}>

    <div className={classes.featureImages}>
     <img  src={food1} alt='' />
     {/* <img src={food2} alt='' /> */}

    </div>
    <div className={classes.featureImages}>
     {/* <img style={{marginBottom:20}} src={food1} alt='' /> */}
     <img src={food2} alt='' />

    </div>
    </Carousel>
    </div>
    </section>
    </div>
  )
}

export default Products