import React from 'react'
import { makeStyles } from '@material-ui/core'
import hotBox from '../images1/Artboard Acaya 3.jpg'
import hotBox2 from '../images1/Artboard Acaya 4.jpg'
import hotBox3 from '../images1/Artboard Acaya 5.jpg'
import hotBox4 from '../images1/Artboard Acaya 6 (1).jpg'
import coldBox from '../images1/Artboard Acaya 7.jpg'
import coldBox2 from '../images1/Artboard Acaya 8.jpg'
import coldBox3 from '../images1/Artboard Acaya 9.jpg'
import coldBox4 from '../images1/Artboard Acaya 10.jpg'

import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const useStyles = makeStyles(theme => ({

    sectionPortfolio: {
        paddingTop: '1rem',
        // borderBottom: '1px solid #e0e0e0',
        // paddingBottom  :'3.2rem',
        width:'95%',
        margin:'0 auto', 

        [theme.breakpoints.down('sm')]: {
            width:'100%',
            paddingTop: '0px',


        },
    },
    productHead: {
        fontWeight: 700,
        fontSize: 48,
        marginTop: 40,
        // textShadow:'4px 4px #e0e0e0',
        letterSpacing: '5px',

        [theme.breakpoints.down('xs')]: {
            fontSize: 24,
            marginTop:0,
        },
    },
    productContent: {
        margin: '10px 0 0px 0'
    },

    productImages: {
        display: 'block',
        width: '100%',
        height: '100%',
        margin:'0 auto',


        '& img': {
            height: '90%',
            width: '-webkit-fill-available',
            // height:'-webkit-fill-available',
        }

    },
    carousel :{
        // maxWidth: '800px',
        margin: '0 auto',
        // padding:50,
        '& .carousel.carousel-slider .control-arrow' :{
            // color:'black !important',
            // background:'black !important',
        },

        '& .carousel .control-next.control-arrow:before':{
        //   borderLeft:'8px solid black',
        //   borderTop:'8px solid black',
        //   borderBottom:'8px solid black',
        margin:'0 5px !important',
        display:'inline-block',
        borderTop:'8px solid transparent',
        borderBottom:'8px solid transparent',
        content:'',
        borderLeft:'8px solid black !important',
        [theme.breakpoints.down('xs')]: {
            display:'none',
        },
        
        },

        '& .carousel .control-prev.control-arrow:before':{
           borderRight:'8px solid black !important',
           fontSize:32,
           [theme.breakpoints.down('xs')]: {
            display:'none',
        },
        },

        '& .carousel .control-dots .dot':{
            background:'black',
            [theme.breakpoints.down('xs')]: {
marginTop:100,
            },
        }
       
      },

}))



const Portfolio = () => {
    const classes = useStyles()

    return (
        <div>
              
                <div className={classes.productContent}>
                <section id='portfolio' className={classes.sectionPortfolio}>

                <Carousel className={classes.carousel} showArrows={true} showStatus={false} showThumbs={false} autoPlay={false} swipeable={true}     >
                    <div className={classes.productImages}>
                        <img style={{ marginBottom: 20 }} src={hotBox} alt='hotBox' />

                    </div>
                    <div className={classes.productImages}>
                        <img style={{ marginBottom: 20 }} src={hotBox2} alt='hotBox' />

                    </div>
                    <div className={classes.productImages}>
                        <img style={{ marginBottom: 20 }} src={hotBox3} alt='hotBox' />

                    </div>
                    <div className={classes.productImages}>
                        <img style={{ marginBottom: 20 }} src={hotBox4} alt='hotBox' />

                    </div>
                    </Carousel>
                    </section>
                    <section id='coldBox' className={classes.sectionPortfolio}>

                    <Carousel className={classes.carousel} showArrows={true} showStatus={false} showThumbs={false} autoPlay={false} swipeable={true} infiniteLoop={true}>
                    <div className={classes.productImages}>
                        <img src={coldBox} alt='hotBox' />

                    </div>
                    <div className={classes.productImages}>
                        <img src={coldBox2} alt='hotBox' />

                    </div>
                    <div className={classes.productImages}>
                        <img src={coldBox3} alt='hotBox' />

                    </div>
                    <div className={classes.productImages}>
                        <img src={coldBox4} alt='hotBox' />

                    </div>
                    </Carousel>
            </section>
            </div>
            </div>

    )
}

export default Portfolio