import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Accordion,AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'

const useStyles = makeStyles(theme => ({

    sectionFaq:{
        paddingTop:'4.2rem',
        borderBottom: '1px solid #e0e0e0',
        paddingBottom  :'3.2rem',
    },

    
    faqHead:{
        fontWeight: 700,
        fontSize: 48,
        marginTop: 40,
        // textShadow:'4px 4px #e0e0e0',
        letterSpacing:'5px',

        [theme.breakpoints.down('sm')]: {
            fontSize: 24,
        },
    },

    faqContent:{
        margin:'40px auto 40px auto',
    width:'60%',
    [theme.breakpoints.down('sm')]: {
        width:'90%',
     },
    },
    questions:{
        textAlign:'start',
        [theme.breakpoints.down('xs')]: {
            fontSize: '10px !important',
        },
    }
}))

const Faq = () => {
    const classes = useStyles()

    const faqData = [
        {
          question: 'How does a smart food packaging solution work?',
          answer: ' Acaya’s food packaging solution works on a special IP based technology , which ensures that the food is fresh and Hot / cold depending on the requirements.',
        },
        {
          question: 'How does Acaya Box contribute to reducing food waste?',
          answer: 'Acaya helps reduce food waste by providing valuable insights into the condition and freshness of food products. By monitoring temperature, and humidity, and maintaining optimal conditions for food freshness, thereby reducing spoilage and waste. ',
        },
        {
            question: 'Is Acaya Box compatible with different types of food products?',
            answer: 'Yes, Acaya Box can be adapted and customized to suit various types of food products. Different sensors and monitoring systems can be employed based on the specific requirements of each product like for Hot Food and for cold ',
          },
          {
            question: 'How does Acaya Box provide information about product freshness?',
            answer: 'Acaya Box utilises sensors to measure factors such as temperature. By analyzing this data, the solution can provide real-time information on product freshness and estimate expiration dates.',
          },
          {
            question: 'Can Acaya Box track and monitor temperature and humidity conditions during storage and transportation?',
            answer: 'Yes Acaya Box utilizes sensors to measure temperature and real-time information on product freshness',
          },
          {
            question: 'What are the potential cost savings associated with implementing Acaya’s Smart Food Packaging?',
            answer: 'Reusable, Reduced food wastage, Enhanced supply chain efficiency.',
          },
          {
            question: 'How does a smart food packaging solution enhance the consumer experience?',
            answer: 'Improved product freshness: Smart packaging helps maintain the quality and freshness of the food, ensuring that consumers receive products in optimal condition. It will help restaurants and food providers maintain a competitive edge, enhance customer satisfaction, and achieve greater efficiency in their delivery processes.',
          },
          {
            question: 'What is the expected lifespan or durability of a smart food packaging solution?',
            answer: 'Then Acaya packaging solution can be reused multiple times. The product is robust enough to handle at least 2-3K deliveries. ',
          },
          {
            question: 'Are there any specific maintenance or servicing requirements for a smart food packaging solution?',
            answer: 'It is always better to take an AMC on the product which allows a complete replacement if there are any issues ( other than physical damage ).',
          },
         
        // Add more FAQ data as needed
      ];

  return (
    <div>
          <section id='faq' className={classes.sectionFaq}>
            <div className={classes.faqHead}>FAQs</div>
            <div className={classes.faqContent}>
          {faqData.map((faq, index) => (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography className={classes.questions}  variant="h6">{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
</div>
</section>
    </div>
  )
}

export default Faq