import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Grid  } from '@mui/material'
import delivery from '../images/motorbike.png'
import safety from '../images/food-safety.png'
import waste from '../images/trash-bin.png'
import customer from '../images/customer-care.png'

const useStyles = makeStyles(theme => ({
    sectionBenefits:{
        paddingTop:'4rem',
        borderBottom: '1px solid #e0e0e0',
        paddingBottom  :'3.2rem',
        background:'#e9eaea',

    },
    benefitsHead:{
        fontWeight: 700,
        fontSize: 48,
        marginTop: 40,
        // textShadow:'4px 4px #e0e0e0',
        letterSpacing:'5px',

        [theme.breakpoints.down('xs')]: {
            fontSize: 24,
        },
    },
    benefitsContent:{
        margin:'40px 0 40px 0',
    },

    firstSide:{
        width:'80%',
        margin:'auto',

        '& img':{
            width:'20%',
            border:'8px solid #eac84d',
            padding:10,
            borderRadius:18,
        },
       
        '& .textContent':{
            padding:'20px 0px 10px 0px',
            margin:'5px auto 1px auto',
            width:'80%',
            textAlign:'center',
            color:'black',
            fontWeight:600,
            lineHeight:'1.4rem',
            [theme.breakpoints.down('xs')]: {
                fontSize: 12,
            },
        }
    }

}))

const Benefits = () => {
    const classes = useStyles()

  return (
    <div>
          <section id='benefits' className={classes.sectionBenefits}>
            <div className={classes.benefitsHead}>Benefits </div>
       <div className={classes.benefitsContent}>
         <Grid container spacing={4}>
         <Grid item xs={12} sm={6}>
            <div className={classes.firstSide}>
              <img src={delivery} alt='del' />
            <div className='textContent'>
            It elevates food safety standards, reduces food waste, and optimizes supply chain management, making it a game-changer in the realm of food delivery.

              </div>
            </div>
         </Grid>

         <Grid item xs={12} sm={6}>
            <div className={classes.firstSide}>
              <img src={customer} alt='del' />
              <div className='textContent'>
              Acaya promises to revolutionize the way people experience food delivery, offering convenience, reliability, and peace of mind.              </div>
            </div>
         </Grid>

         <Grid item xs={12} sm={6}>
            <div className={classes.firstSide}>
              <img src={safety} alt='del' />
              <div className='textContent'>
              One of the key benefits of Acaya is its ability to enhance food safety. With advanced technology and innovative features, it ensures that food is handled and transported in a safe and hygienic manner, reducing the risk of contamination and ensuring customer well-being.
              </div>
            </div>
         </Grid>
         <Grid item xs={12} sm={6}>
            <div className={classes.firstSide}>
              <img src={waste} alt='del' />
              <div className='textContent'>
              Optimizing the supply chain and implementing efficient tracking systems, it minimizes the chances of food spoilage and expiration. This not only benefits the environment but also helps businesses save costs and resources, ultimately leading to a more sustainable food delivery ecosystem.
              </div>
            </div>
         </Grid>
         </Grid>
       </div>
    
</section>
    </div>
  )
}

export default Benefits