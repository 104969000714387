import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import facebook from '../images/facebook_1384005.svg'
import instagram from '../images/instagram_1384015.svg'
import twitter1 from '../images/twitter.png'
import linkedin from '../images/linkedin_3536569.svg'
import acayaLogo from '../images/Acaya-loggo-385x77 (1).png';

const useStyles = makeStyles(theme => ({

    mainFooter:{

    paddingTop: '1.2rem',
    borderTop: '1px solid #e0e0e0',
    paddingBottom: '1.2rem',
    background: '#ffff',
    width:'100%',
    margin:'0 auto', 
    // opacity:0.7,
    height: '2.8rem',
   

    '& .socialIcons':{
        display:'flex',
    justifyContent:'space-between',
    paddingRight:50,
        gap:14,

        '& .icons':{
            maxWidth:25,
            '& img':{
                width:'-webkit-fill-available',
            }
        }
    },
    '& .rights':{
        display:'flex',
        justifyContent:'center',
        fontSize:10,
        marginTop:10,

    }
    },

}))


const Footer = () => {
    const classes = useStyles()

  return (
    <div className={classes.mainFooter}>
     <div>
     <style>
@import url('https://fonts.googleapis.com/css2?family=Arimo&family=Kalam&family=Nunito+Sans:opsz@6..12&display=swap');
</style>
     <div className='socialIcons'>
<div style={{textAlign:'start'}}>
    <img style={{width:'35%', paddingLeft:30}} src={acayaLogo} alt=''/>
</div>

        <div style={{display:'flex', gap:20,}}>
    <div className='icons'>
       <a href='https://www.facebook.com/profile.php?id=100094472314624' target="_blank"> <img src={facebook} alt='' /></a>
    </div>
   
    <div className='icons'>
    <a href='https://www.instagram.com/acaya_india/' target='_blank'><img src={instagram} alt='' /></a>
    </div>
    <div className='icons'>
   <a href='https://www.linkedin.com/company/acaya-packaging-tech-pvt-ltd/' target='_blank'> <img src={linkedin} alt='' /></a>
    </div>
    <div className='icons'>
   <a href='https://twitter.com/Acaya_India?t=-UMn6GQOax_E4D9l6wQaFg&s=09' target='_blank'> <img src={twitter1} alt='test' /></a>
    </div>
    </div>

</div>


     </div>
     <div style={{display:'flex', justifyContent:'center'}}>
     <div className='rights'>
2023 © - all rights reserved

</div>
</div>
    </div>
  )
}

export default Footer